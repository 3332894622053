import React from 'react';

const PhotosContent = () => {
    return (
        <div className='photosContent'>
            <div className='column firstColumn'>
                <img src='/ressources/images/Photo13_24A.jpg' alt="test"/>
                <img src='/ressources/images/Photo35_2A.jpg' alt="test"/>
                <img src='/ressources/images/Photo11_26A.jpg' alt="test"/>
                <img src='/ressources/images/compressjpeg/DSC04318.jpg' alt="test"/>
                <img src='/ressources/images/Photo32_5A.jpg' alt="test"/>
                <img src='/ressources/images/compressjpeg/000029.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000031.jpg' alt="test" />
                <img src='./ressources/images/compressjpeg/000009.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000017.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000009-2.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/DSCF3496.jpg' alt="test"/>
            </div>
            <div className='column secondColumn'>
                <img src='./ressources/images/compressjpeg/DSC04616.jpg' alt="test"/>
                <img src='/ressources/images/Photo29_8A.jpg' alt="test"/>
                <img src='/ressources/images/Photo03_34A.jpg' alt="test"/>
                <img src='/ressources/images/compressjpeg/000021.jpg' alt="test"/>
                <img src='/ressources/images/Photo12_25A.jpg' alt="test"/>

                {/* <img src='./ressources/images/compressjpeg/000039.jpg' alt="test"/> */}
                <img src='./ressources/images/compressjpeg/000010.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000042.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000015.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000011.jpg' alt="test"/>

                <img src='./ressources/images/compressjpeg/_DSC3782.jpg' alt="test"/>
            </div>
            <div className='column thirdColumn'>
                <img src='/ressources/images/compressjpeg/DSC04256.jpg' alt="test"/>
                <img src='/ressources/images/Photo25_12A.jpg' alt="test"/>

                <img src='./ressources/images/compressjpeg/DSC04656.jpg' alt="test"/>
                <img src='/ressources/images/Photo33_4A.jpg' alt="test"/>

                <img src='./ressources/images/compressjpeg/000033.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/000014.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/DSC04605.jpg' alt="test"/>

                <img src='./ressources/images/compressjpeg/000018.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/_DSC3761.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/Photo01_36.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/DSCF3467.jpg' alt="test"/>
                <img src='./ressources/images/compressjpeg/_DSC3734.jpg' alt="test"/>
            </div>
        </div>
    );
};

export default PhotosContent;