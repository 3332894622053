import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Winter = () => {

    return (
        <div className='alinea'>
            <Navigation/>
            <div className='longLiveContent'>
                <div className='player'><ReactPlayer url="https://vimeo.com/1036675174" controls/></div>
                <div className='credits'>
                    <h1>Grama Winter</h1>
                    <br/>
                    <p>
                    <span>Production</span> Abrupt <br/>
                    <span>Director</span> Marie Vivière - Alexandre Meunier <br/>
                    <span>DOP</span> Alexandre Meunier <br/>
                    <span>Ass cam / Electrician</span> Alexandre Thimonier <br/>
                    <span>Stylist</span> Julie Simoneau <br/>
                    <span>MUAH</span> Laurette Darnaut <br/>
                    <span>Color Grading</span> Alexandre Meunier <br/>
                    <span>Cast</span> Anissa - Clara - Mathis - Pierre Philippe 
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Winter;