import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Alinea = () => {

    return (
        <div className='alinea'>
            <Navigation/>
                <div className='longLiveContent'>
                    <div className='player'>
                        <ReactPlayer url="https://vimeo.com/779307425" controls/>
                        
                    </div>
                    <div className='credits'>
                        <h1>Alinéa collection été</h1>
                        <br/>
                        <p>
                            <span>Production</span> Abrupt <br/>
                            <span>Director</span> Loic Foulon<br/>
                            <span>Producers</span> Marie Vivière - Alexandre Meunier<br/>
                            <span>DOP</span> Julien Jauffret<br/>
                            <span>Electrician</span> Arthur Cardenas<br/>
                            <span>Editor</span> Aurélien Baud<br/>
                            <span>Color Grading</span>  Julien Jauffret<br/>
                            <span>Music mix and mastering</span> Antoine Duchene 
                        </p>
                    </div>
                </div>
                
            </div>
    );
};

export default Alinea;