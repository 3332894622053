import React from 'react';
import ReactPlayer from "react-player"
import Navigation from '../../components/Navigation';



const Automne = () => {

    return (
        <div className='alinea'>
            <Navigation/>
                <div className='longLiveContent'>
                    <div className='player'>
                        <ReactPlayer url="https://vimeo.com/1034463052" controls/>
                        
                    </div>
                    <div className='credits'>
                        <h1>Alinéa collection automne</h1>
                        <br/>
                        <p>
                            <span>Production</span> Abrupt <br/>
                            <span>Director</span> Marie Vivière - Alexandre Meunier<br/>
                            <span>DOP</span> David Capsir<br/>
                            <span>Ass cam</span> Sébastien Roche<br/>
                            <span>Electrician</span> Némo del Aguila<br/>
                            <span>Color Grading</span> Alexandre Meunier<br/>
                            <span>Music mix and mastering</span>  Florian Adrien<br/>
                            <span>Equipment rental</span> Indie Location 
                        </p>
                    </div>
                </div>
                
            </div>
    );
};

export default Automne;